import React, { Component } from "react";

import "./backtotop.scss";

class BackToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonStyle: {
        top: "-900px"
      }
    };
    this.backToTop = this.backToTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const position = window.pageYOffset || document.documentElement.scrollTop;
      if (position > 100) {
        this.setState({
          buttonStyle: {
            top: "-166px"
          }
        });
      } else {
        this.setState({
          buttonStyle: {
            top: "-900px"
          }
        });
      }
    });
  }

  backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    this.setState({
      buttonStyle: {
        top: "-900px"
      }
    });
  };

  render() {
    const { buttonStyle } = this.state;
    return (
      <div
        className="back-to-top"
        style={buttonStyle}
        onClick={this.backToTop}
        onKeyPress={null}
        role="button"
        tabIndex="0"
      />
    );
  }
}

export default BackToTop;
